<!--
 * ======================================
 * 说明： 教师端 - 学业水平
 * 作者： Silence
 * 文件： study.vue
 * 日期： 2023/8/3 0:32
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="p-6" v-loading="loading">
    <canvas v-show="false" id="mycanvas"></canvas>
    <div class="my-4">
      <el-form :inline="true" :model="formSearch" class="web-form-inline">
        <SemesterClassSelect v-model="formSearch" @onLoadFinish="handleLoadDataInfo" @change="handleLoadDataInfo" @refresh="handleLoadDataInfo"></SemesterClassSelect>
      </el-form>
    </div>
    <el-button type="success" @click="onDrawReport">导出报告</el-button>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick" v-if="options.length">
      <el-tab-pane :label="menu.label" :name="index.toString()" v-for="(menu,index) in options" :key="index">

        <otherGrades ref="otherGrades" v-model="formSearch" :isIE="false" :file-name="menu.label" :headers="menu.children"
                     v-if="activeName==index"/>

      </el-tab-pane>
        <Editor
            id="hidden-rich-editor"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
            style="width: 1300px;height: auto;overflow-y: hidden;font-size: 36px;position: fixed;top: -1000px"

        />
<!--      position:fixed;top: -1000px-->
    </el-tabs>
    <el-empty v-else description="暂无任务数据"></el-empty>
  </div>
</template>
<script type="text/javascript">

import otherGrades from "./components/otherGrades";
import blockScoreList from "./components/blockScoreList.vue";
import drawReport from '@/mixin/drawReport.js'


import { Editor } from '@wangeditor/editor-for-vue'
import html2canvas from "html2canvas";

export default {
  name: "medal",
  components: {
    otherGrades,
    blockScoreList,
      Editor
  },
  mixins: [drawReport],
  data() {
    return {
      loading:false,
      //搜索表单结构
      formSearch: {
        semesterId: "",//学期ID
        blockId: "",//模块ID
        grade: '',//年级ID
        classId: '',//班级ID
        subjectId: 3,//任课ID
        realName: ''
      },
      activeName: '0',
      options: [],
      blockOptions: [],
        editor: null,
        html: '',
        editorConfig: { },
        mode: 'simple',
        editorNum:0,
    };
  },
  /**
   * 接收父级传递的数据
   */
  inject: ["WEB"],
  methods: {
      onCreated(editor) {
          this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },
    handleClick(tab, event) {
      let menuInfo = this.options[this.activeName];
      this.$nextTick(() => {
        if( this.formSearch && menuInfo){
          this.formSearch.blockId = menuInfo["key"];
          console.log('otherGrades',this.$refs.otherGrades,this.activeName)
          if(this.$refs.otherGrades &&  this.$refs.otherGrades[0]){
            console.log('加载tab数据',this.$refs.otherGrades[0])
            let dom = this.$refs.otherGrades[0]
            dom.handleLoadDataList()
          }
        }
      })

    },
    async handleLoadDataInfo() {
      console.log('加载')
      await this.handleStudyOption();
      await this.handleClick();
    },
    /**
     * 获取数据选项
     */
    handleStudyOption() {
      if(!this.formSearch.grade){
        return
      }
      //获取分页数据结构
      return this.$cloud.get("task/block", this.formSearch).then(res => {
        this.options = [];
        if (res.length && res) {
          this.options = res
        }
      }).catch(err => {
        this.options = [];
      })
    },
  },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss">
.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
</style>
